
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { useForm, Field } from "vee-validate";
import * as Yup from "yup";
import Step1 from "@/views/reusable/buy-insurance/house-insurance/edit/building/RentBuildingStep1.vue";
import Step2 from "@/views/reusable/buy-insurance/house-insurance/edit/building/OwnBuildingStep2.vue";
import Step3 from "@/views/reusable/buy-insurance/house-insurance/edit/building/OwnBuildingStep3.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";

interface Step0 {
  policySummary: object;
}

// eslint-disable-next-line no-redeclare
interface Step1 {
  buildingType: string;
  state: string;
  localGovernmentArea: string;
  ward: string;
  streetAddress: string;
  alternativeAccommodation: string;
  imagesOfBuilding: string;
}

// eslint-disable-next-line no-redeclare
interface Step2 {
  houseHoldGoodsAndPersonalEffectsValue: string;
  cashValue: string;
  thirdPartyLiabilityLimit: string;
  schedule: string;
  phoneDetails: {
    imageOfReceipt: string;
    serialNumber: string;
    value: string;
  };
  laptopOrTabletDetails: {
    imageOfReceipt: string;
    serialNumber: string;
    value: string;
  };
  jewelryDetails: {
    brand: string;
    imageOfReceipt: string;
    value: string;
  };
  cameraDetails: {
    brand: string;
    imageOfReceipt: string;
    value: string;
  };
  musicalInstrumentDetails: {
    brand: string;
    imageOfReceipt: string;
    value: string;
  };
  inverterDetails: {
    imageOfReceipt: string;
    serialNumber: string;
    value: string;
  };
}

// eslint-disable-next-line no-redeclare
interface Step3 {
  generators: {
    generatorValue: string;
    generatorBrand: string;
    generatorPhoto: string;
    generatorReceiptPhoto: string;
  };
}

interface CreateRentBuilding extends Step0, Step1, Step2, Step3 {}

export default defineComponent({
  name: "edit-rented-building-house-insurance",
  components: { Field, Step1, Step2, Step3, TotalPremiumWidget },
  data() {
    return {
      publicId: this.$route.params.publicId,
      totalPremium: 0.0 as number,
      underwriters: [],
      alternativeAccommodationValueRate: 0.001,
      personalEffectsRate: 0.045,
      cashValueRate: 0.006,
      thirdPartLiabilityValueRate: 0.00075,
      generatorRate: 0.0025,
      phoneRate: 0.05,
      laptopRate: 0.02,
      cameraRate: 0.01,
      inverterRate: 0.0025,
      musicalInstrumentRate: 0.01,
      jewelryRate: 0.01,
      policySummary: {},
      totalSumInsured: 0.0 as number
    };
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const formData = ref<CreateRentBuilding>({
      policySummary: {},
      buildingType: "",
      state: "",
      localGovernmentArea: "",
      ward: "",
      streetAddress: "",
      alternativeAccommodation: "",
      imagesOfBuilding: "",
      houseHoldGoodsAndPersonalEffectsValue: "",
      cashValue: "",
      thirdPartyLiabilityLimit: "",
      schedule: "",
      phoneDetails: {
        imageOfReceipt: "",
        serialNumber: "",
        value: ""
      },
      laptopOrTabletDetails: {
        imageOfReceipt: "",
        serialNumber: "",
        value: ""
      },
      jewelryDetails: {
        brand: "",
        imageOfReceipt: "",
        value: ""
      },
      cameraDetails: {
        brand: "",
        imageOfReceipt: "",
        value: ""
      },
      musicalInstrumentDetails: {
        brand: "",
        imageOfReceipt: "",
        value: ""
      },
      inverterDetails: {
        imageOfReceipt: "",
        serialNumber: "",
        value: ""
      },
      generators: {
        generatorValue: "",
        generatorBrand: "",
        generatorPhoto: "",
        generatorReceiptPhoto: ""
      }
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );
    });

    const CreateRentBuildingSchema = [
      Yup.object({
        buildingType: Yup.mixed().required().label("Type of building"),
        state: Yup.string().required().label("State"),
        localGovernmentArea: Yup.string().required().label("L.G.A"),
        ward: Yup.string().required().label("Town/City"),
        streetAddress: Yup.string().required().label("Street address"),
        alternativeAccommodation: Yup.string()
          .required()
          .label("Value for alternative accommodation"),
        imagesOfBuilding: Yup.mixed().required().label("Photo of building")
      }),
      Yup.object({
        houseHoldGoodsAndPersonalEffectsValue: Yup.string()
          .required()
          // .min(10000)
          .label("Contents value"),
        cashValue: Yup.string()
          .notRequired()
          // .min(10000)
          .label("Cash value"),
        thirdPartyLiabilityLimit: Yup.string()
          .notRequired()
          // .min(10000)
          .label("Third-party liability value")
      }),
      Yup.object({
        generatorValue: Yup.string().notRequired().label("Generator value"),
        generatorBrand: Yup.mixed().notRequired().label("Generator brand"),
        generatorPhoto: Yup.mixed().notRequired().label("Photo of generator"),
        generatorReceiptPhoto: Yup.mixed()
          .notRequired()
          .label("Photo of generator receipt")
      })
    ];

    // const currentSchema = computed(() => {
    //   return CreateRentBuildingSchema[currentStepIndex.value];
    // });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3>({
      // validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value
      }
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values
      };

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();

      const grandPremium = document.getElementById(
        "total-premium-field"
      ) as HTMLInputElement;

      // Append total premium hidden field
      grandPremium.value = variables.totalPremiumValue() as any;
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    return {
      horizontalWizardRef,
      previousStep,
      handleStep,
      totalSteps,
      currentStepIndex
    };
  },
  created() {
    // Get the rates for house bulding package
    this.getHouseBuildingRates();

    // Get house insurance available underwriters list
    this.getHouseUnderwriters();

    // Get invoice information
    this.getInvoiceInformation();

    //Set page title
    document.title =
      "Edit Rented Building House Insurance Invoice | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getInvoiceInformation() {
      const router = useRouter();

      //Get cart item details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CART_ROUTE}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.policySummary = data.data;
            this.totalPremium = data.data.premium;
            this.totalSumInsured = data.data.sumInsured;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            router.push({ name: "insurance-packages-cart" });
          });
      }
    },
    getHouseBuildingRates() {
      //Fetch list of underwriters for house building insurance package
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.HOUSE_BUILDING_RATE_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.alternativeAccommodationValueRate =
              data.data.alternativeAccommodation.value;
            this.personalEffectsRate = data.data.personalEffects.value;
            this.cashValueRate = data.data.cash.value;
            this.thirdPartLiabilityValueRate =
              data.data.thirdPartyLiabilityCover.value;
            this.generatorRate = data.data.generatorOrInverter.value;
            this.phoneRate = data.data.phone.value;
            this.laptopRate = data.data.laptopOrTablet.value;
            this.cameraRate = data.data.camera.value;
            this.inverterRate = data.data.generatorOrInverter.value;
            this.musicalInstrumentRate = data.data.musicalInstrument.value;
            this.jewelryRate = data.data.jewelry.value;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getHouseUnderwriters() {
      //Fetch list of States in Nigeria
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.HOUSE_UNDERWRITERS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.underwriters = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formSubmit(e) {
      e.preventDefault();
      const submitButton1 = document.getElementById("home_submit_btn");

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        const form = document.forms[0];
        const formData = new FormData(form);
        formData.append("package", "BUI");
        formData.append("packageType", "TEN");

        // return new Response(formData).text().then(console.log);
        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(
            `${variables.CART_ROUTE}/${this.publicId}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
            .then((response) => {
              // console.log(response);
              submitButton1.removeAttribute("data-kt-indicator");
              variables.toastAlert(response.data.data.message, "success");
              this.$router.push({
                name: "insurance-packages-policy-period",
                params: {
                  publicId: response.data.data.cartItemPublicId
                }
              });
            })
            .catch(function (error) {
              submitButton1.removeAttribute("data-kt-indicator");
              console.info(error);
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    }
  }
});
