
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import State from "@/components/reusable/State.vue";
import Address from "@/components/reusable/Address.vue";
import variables from "@/router/api";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import BuildingTypes from "@/views/reusable/buy-insurance/house-insurance/building-types.json";

export default defineComponent({
  name: "own-building-step-1",
  components: {
    Field,
    ErrorMessage,
    State,
    Address,
    ImagesUploader,
  },
  props: {
    alternativeAccommodationValueRate: Number,
    policySummary: Object,
  },
  data() {
    return {
      buildingTypes: BuildingTypes,
      totalPremium: 0.0 as number,
    };
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Calculate premium from vehicle value
        variables.premiumCalculator(
          event.target.ariaValueNow,
          value,
          event.target.ariaValueText
        );
      } else {
        return;
      }

      // Append total premium value tot total premium field
      this.totalPremium = variables.totalPremiumValue();
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
  computed: {
    mainPolicySummary() {
      return this.policySummary;
    },
  },
});
