
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ContentsBuildingModal from "@/components/modals/ContentsBuildingModal.vue";
import ScheduleInfoModal from "@/components/modals/ScheduleInfoModal.vue";
import Contents from "@/components/reusable/Contents.vue";
import variables from "@/router/api";

export default defineComponent({
  name: "own-building-step-2",
  components: {
    Field,
    ErrorMessage,
    ContentsBuildingModal,
    ScheduleInfoModal,
    Contents
  },
  props: {
    personalEffectsRate: Number,
    cashValueRate: Number,
    thirdPartLiabilityValueRate: Number,
    phoneRate: Number,
    laptopRate: Number,
    cameraRate: Number,
    inverterRate: Number,
    musicalInstrumentRate: Number,
    jewelryRate: Number,
    policySummary: Object
  },
  data() {
    return {
      displayContent: false,
      totalPremium: 0.0 as number
    };
  },
  created() {
    this.displayContents();
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Calculate premium from vehicle value
        variables.premiumCalculator(
          event.target.ariaValueNow,
          value,
          event.target.ariaValueText
        );
      } else {
        return;
      }

      // Append total premium value tot total premium field
      this.totalPremium = variables.totalPremiumValue();
    },
    displayContents() {
      if (Object.keys(this.policySummary as object).length > 0) {
        this.displayContent = true;
      } else {
        this.displayContent = false;
      }

      this.displayContent = this.displayContent == true ? false : true;

      if (this.displayContent == false) {
        setTimeout(() => {
          // Update total premium
          this.totalPremium = variables.totalPremiumValue();
        }, 200);
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    }
  },
  computed: {
    mainPolicySummary() {
      return this.policySummary;
    }
  }
});
