
import { defineComponent } from "vue";
import Generator from "@/components/reusable/Generator.vue";
import EditGenerator from "@/components/reusable/EditGenerator.vue";
import Insurer from "@/components/reusable/Insurer.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import $ from "jquery";

export default defineComponent({
  name: "own-building-step-3",
  components: {
    Generator,
    EditGenerator,
    Insurer
  },
  props: {
    underwriters: Array,
    generatorRate: Number,
    policySummary: Object,
    cartItemPublicId: String
  },
  data() {
    return {
      totalPremium: 0.0 as number,
      imageURL: ""
    };
  },
  created() {
    // Policy images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Calculate premium from vehicle value
        variables.premiumCalculator(
          event.target.ariaValueNow,
          value,
          event.target.ariaValueText
        );
      } else {
        return;
      }

      // Append total premium value tot total premium field
      this.totalPremium = variables.totalPremiumValue();
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/no-photo-available.png";
    },
    deleteImage(index, fileName) {
      console.log(index, fileName);
      const route = `${variables.CART_ROUTE}/${this.cartItemPublicId}/${fileName}`;
      Swal.fire({
        title: "Are you sure you want to delete this image?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007ae1",
        cancelButtonColor: "#ff3e61",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.value == true) {
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.delete(route)
              .then(({ data }) => {
                //Assign data to form fields
                variables.toastAlert(data.data, "success");
                $(".remove-image-" + index).remove();
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }
      });
    }
  },
  computed: {
    mainPolicySummary() {
      return this.policySummary;
    }
  }
});
