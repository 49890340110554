
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import variables from "@/router/api";

export default defineComponent({
  name: "generator-edit-component",
  components: { Field, ErrorMessage },
  props: {
    valueClasses: String,
    brandClasses: String,
    photoClasses: String,
    photoReceiptClasses: String,
    rate: Number,
    oldGenerators: Array
  },
  data() {
    return {
      generators: [
        {
          generatorValue: "",
          generatorBrand: "",
          generatorPhoto: "",
          generatorReceiptPhoto: ""
        }
      ],
      totalPremium: 0.0 as number
    };
  },
  methods: {
    removeComma(amount) {
      return variables.removeComma(amount);
    },
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Calculate premium from vehicle value
        variables.premiumCalculator(
          event.target.ariaValueNow,
          value,
          event.target.ariaValueText
        );
      } else {
        return;
      }

      // Append total premium value tot total premium field
      this.totalPremium = variables.totalPremiumValue();
    },
    addDynamicField() {
      this.generators.push({
        generatorValue: "",
        generatorBrand: "",
        generatorPhoto: "",
        generatorReceiptPhoto: ""
      });
      console.log(this.generators.push());
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      this.generators.splice(counter, 1);

      setTimeout(() => {
        // Update total premium
        this.totalPremium = variables.totalPremiumValue();
      }, 200);
    },
    imagesSelected(event, position) {
      this.generators[position].generatorPhoto = event.target.files;
    },
    receiptsSelected(event, position) {
      this.generators[position].generatorReceiptPhoto = event.target.files;
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    }
  },
  computed: {
    mainRate() {
      return this.rate;
    },
    mainGenerators() {
      return this.oldGenerators;
    }
  }
});
